var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("Icon", { attrs: { type: "ios-information-circle" } }),
          _c("span", [_vm._v(_vm._s(_vm.title))])
        ],
        1
      ),
      _c(
        "Form",
        {
          ref: "formValidate",
          attrs: { model: _vm.merchant, rules: _vm.rules, "label-width": 120 }
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "挂靠商家", prop: "name" } },
            [
              _c("Input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.merchantItem.name,
                  callback: function($$v) {
                    _vm.$set(_vm.merchantItem, "name", $$v)
                  },
                  expression: "merchantItem.name"
                }
              })
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "商家名称", prop: "name" } },
            [
              _c("Input", {
                attrs: { placeholder: "请输入商家名称" },
                model: {
                  value: _vm.merchant.name,
                  callback: function($$v) {
                    _vm.$set(_vm.merchant, "name", $$v)
                  },
                  expression: "merchant.name"
                }
              })
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "类型", prop: "type" } },
            [
              _c(
                "RadioGroup",
                {
                  attrs: { disabled: "", type: "button" },
                  model: {
                    value: _vm.merchant.type,
                    callback: function($$v) {
                      _vm.$set(_vm.merchant, "type", $$v)
                    },
                    expression: "merchant.type"
                  }
                },
                _vm._l(this.typeEnum, function(item, index) {
                  return _c(
                    "Radio",
                    { key: index, attrs: { disabled: "", label: item.code } },
                    [_vm._v(" " + _vm._s(item.value) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "联系电话", prop: "phone" } },
            [
              _c("Input", {
                attrs: { placeholder: "请输入联系电话" },
                model: {
                  value: _vm.merchant.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.merchant, "phone", $$v)
                  },
                  expression: "merchant.phone"
                }
              })
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "营业执照编号", prop: "idNumber" } },
            [
              _c("Input", {
                attrs: { placeholder: "请输入营业执照编号" },
                model: {
                  value: _vm.merchant.idNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.merchant, "idNumber", $$v)
                  },
                  expression: "merchant.idNumber"
                }
              })
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "公司地址", prop: "address" } },
            [
              _c("Input", {
                attrs: { placeholder: "请输入公司地址" },
                model: {
                  value: _vm.merchant.address,
                  callback: function($$v) {
                    _vm.$set(_vm.merchant, "address", $$v)
                  },
                  expression: "merchant.address"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.merchantItem
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleAddMerchantItem }
                },
                [_vm._v("添加商家")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }