<template>
    <Modal v-model="showDialog">
        <template slot="header">
            <Icon type="ios-information-circle"></Icon>
            <span>{{title}}</span>
        </template>
        <Form ref="formValidate" :model="merchant" :rules="rules" :label-width="120">
            <FormItem label="商家名称" prop="name">
                <Input v-model="merchant.name" placeholder="请输入商家名称"></Input>
            </FormItem>
            <FormItem label="类型" prop="type">
              <RadioGroup v-model="merchant.type" type="button">
                <Radio :label="item.code" v-for="(item, index) in this.typeEnum" :key="index">
                  {{ item.value }}
                </Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="联系电话" prop="phone">
                <Input v-model="merchant.phone" placeholder="请输入联系电话"></Input>
            </FormItem>
            <FormItem label="营业执照编号" prop="idNumber">
                <Input v-model="merchant.idNumber" placeholder="请输入营业执照编号"></Input>
            </FormItem>
            <FormItem label="公司地址" prop="address">
                <Input v-model="merchant.address" placeholder="请输入公司地址"></Input>
            </FormItem>
        </Form>
        <template slot="footer">
            <Button type="primary" :loading="loading" @click="handleAddMerchantItem" v-if="!merchantItem">添加商家</Button>
            <Button type="primary" @click="handleUpdateMerchantItem" :loading="loading" v-else>修改商家</Button>
        </template>
    </Modal>
</template>

<script>
    import dialogMixin from '_m/dialog.mixin'

    export default {
        name: 'MerchantDialog',
        mixins: [dialogMixin],
        data () {
            return {
                typeEnum: [ { code: 0, value: '平台' }, { code: 1, value: '普通商家' }, { code: 2, value: '超级商家' } ],
                showDialog: true,
                loading: false,
                merchant: {
                    address: '',
                    idNumber: '',
                    name: '',
                    phone: '',
                }
            }
        },
        created () {
            if (this.merchantItem) {
                Object.assign(this.merchant, this.merchantItem)
            }
        },
        computed: {
            title () {
                return this.merchantItem ? '修改商家' : '添加商家'
            },
            rules () {
                const rules = {
                    name: [
                        { required: true, message: '商家名字不能为空', trigger: 'blur' }
                    ],
                    idNumber: [
                        { required: true, message: '营业执照编号不能为空', trigger: 'blur' },
                        { len: 18, message: '营业执照编号必须为18位', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: '联系电话不能为空', trigger: 'blur' }
                    ]
                }
                return rules
            }
        },
        props: {
            merchantItem: {
                type: Object,
                required: false,
                default: () => null
            }
        },
        methods: {
            /**
             * 添加表单项
             */
            handleAddMerchantItem () {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$http.post('/server/v1/Merchant/add', this.merchant).then((response) => {
                            let { code, msg } = response
                            if (code === 1) {
                                this.$emit('on-success')
                                this.showDialog = false
                            } else {
                                this.$Notice.error({
                                    title: `code=${code}`,
                                    desc: msg
                                })
                            }
                        }).finally(() => {
                            this.loading = false
                        })
                    } else {
                        this.$Notice.error({
                            title: '表单填写错误',
                            desc: '请正确填写表单'
                        })
                    }
                })
            },
            /**
             * 修改表单项
             */
            handleUpdateMerchantItem () {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$http.post(`/server/v1/Merchant/update`, this.merchant).then((response) => {
                            const { code, msg } = response
                            if (code === 1) {
                                this.$emit('on-success')
                                this.showDialog = false
                            } else {
                                this.$Notice.error({
                                    title: `code=${code}`,
                                    desc: msg
                                })
                            }
                        }).finally(() => {
                            this.loading = false
                        })
                    } else {
                        this.$Notice.error({
                            title: '表单填写错误',
                            desc: '请正确填写表单'
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
</style>
