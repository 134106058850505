<template>
  <div>
    <!--模态框-->
    <div v-if="showDialog">
      <merchant-dialog v-model="showDialog" :merchant-item="currentMerchantItem" @on-success="handleSuccess" />
    </div>

        <div v-if="showDialog2">
          <merchant-dialog2 v-model="showDialog2" :merchant-item="currentMerchantItem" @on-success="handleSuccess" />
        </div>

    <Card ref="header">
      <div style="display: flex">
        <Button type="primary" icon="md-add" style="margin-left: auto;" @click="handleAddMerchant">添加超级商家</Button>
      </div>
    </Card>
    <!--表格-->
    <Table row-key="id" :tooltip-theme="$store.state.theme" :height="tableHeight" :loading="loading"
      :columns="tableColumns" :data="tableData" stripe border></Table>

    <!-- 分页 -->
    <Page ref="footer" :total="total" :current="currentPage" show-total show-sizer @on-change="handleCurrentChange"
      @on-page-size-change="handlePageSize" style="float: right;" />
  </div>
</template>

<script>
import MerchantDialog from './MerchantDialog'
import MerchantDialog2 from './MerchantDialog2'
import tableHeightMixin from '_m/tableHeight.mixin'
import tablePageMixin from '_m/tablePage.mixin'

export default {
  name: 'Merchant',
  mixins: [tableHeightMixin, tablePageMixin],
  components: {
    MerchantDialog,
    MerchantDialog2
  },
  data () {
    return {
      tableData: [],
      showDialog: false,
      showDialog2: false,
      currentMerchantItem: null,
    }
  },
  created () {
    this.initData()
  },
  computed: {
    tableColumns () {
      let columns = []
      columns.push({
        title: '商家名称',
        tree: true,
        key: 'name',
        align: 'center',
      })
      columns.push({
        title: '类型',
        key: 'type',
        align: 'center',
      render: (h, params) => {
        const state = params.row.type
        let a = '普通商家'
        if (state === 2) {
            a = '超级商家'
        } else if (state === 0) {
            a = '平台'
        }
        return h('div', {
        }, a)
      }
      })
    columns.push({
      title: '状态',
      key: 'status',
      align: 'center',
    render: (h, params) => {
      const state = params.row.status
      let a = '正常'
      if (state !== 0) {
          a = '禁用'
      }
      return h('div', {
      }, a)
    }
    })
      columns.push({
        title: '挂靠商家',
        align: 'center',
      render: (h, params) => {
        const value = params.row.parent.name
        return h('div', {
        }, value)
      }
      })
      columns.push({
        title: '营业执照编号',
        key: 'idNumber',
        align: 'center',
      })
      columns.push({
        title: '联系电话',
        key: 'phone',
        align: 'center',
      })
      columns.push({
        title: '公司地址',
        key: 'address',
        align: 'center',
      })
      columns.push({
        title: '操作',
        align: 'center',
        width: 350,
        render: (h, params) => {
          const array = []
          const { row } = params
          array.push(h('Button', {
            style: {
              marginRight: '5px'
            },
            props: {
              icon: 'ios-update',
              type: 'warning',
              size: 'small'
            },
            on: {
              click: () => {
                this.handleUpdateMerchantItem(row)
              }
            }
          }, '修改'))
          if (row.type === 2) {
            array.push(h('Button', {
              props: {
                icon: 'ios-trash',
                type: 'error',
                size: 'small'
              },
              on: {
                click: () => {
                  this.handleAddNormalMerchantItem(row)
                }
              }
            }, '添加普通商家'))
          }
          return h('div', array)
        }
      })
      return columns
    }
  },
  methods: {
    initData () {
      this.loading = true
      this.$http.post(`/server/v1/Merchant/page`, {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        t: {
          status: 0
        }
      }).then((response) => {
        const { code, data } = response
        if (code === 1) {
        let { rows, totalRows, code, msg } = data
                if (rows) {
                  this.tableData = rows
                  this.total = totalRows
                }
        } else {
          this.$Notice.error({
            title: `接口报错:code=${code}`,
            desc: msg
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSuccess () {
      this.initData()
    },
    /**
     * 添加顶层菜单
     */
    handleAddMerchant () {
      this.currentMerchantItem = null
      this.showDialog = true
    },
    /**
     * 修改菜单
     * @param menuItem
     */
    handleUpdateMerchantItem (menuItem) {
      this.currentMerchantItem = menuItem
      this.showDialog = true
    },
    handleAddNormalMerchantItem (menuItem) {
      this.currentMerchantItem = menuItem
      this.showDialog2 = true
    },
    /**
     * 删除菜单项
     * @param menu
     */
    handleDeleteMerchantItem (merchant) {
      this.$Modal.confirm({
        title: '删除确认',
        content: `<p>确认删除商家-${merchant.name}?</p>`,
        onOk: () => {
          this.$http.delete(`/server/v1/Merchant/delete/${merchant.id}`).then((response) => {
            const { code, msg } = response
            if (code === 1) {
              this.initData()
            } else {
              this.$Notice.error({
                title: `接口报错:code=${code}`,
                desc: msg
              })
            }
          })
        }
      })
    }
  },
}
</script>
